
























































import { Vue, Component, Prop } from 'vue-property-decorator';
import userDetail from '@/components/UserDetail/index.vue';
import {
  UserListEditSvc,
  UserPermissionSvc,
  getAllEnterpriseSvc,
  getUserByKeySvc,
} from '@/services/systemApi';
/* eslint-disable @typescript-eslint/naming-convention */

@Component({
  components: {
    userDetail,
  },
})
export default class OperateUser extends Vue {
  @Prop({
    type: Object,
    required: false,
    default: {},
  })
  data!: '';
  @Prop({
    type: Number,
    required: false,
    default: 1,
  })
  currentPage!: 0;
  serviceName: any = '';
  userManage = true;
  $dayjs: any;
  $domainConfig: any;
  userName = '';
  phone = '';
  en_tel = '';
  message = '';
  value = '';
  server: any = [];
  functionPermission: any = {};
  loading = true;
  data1: any = [];
  modal1 = false;
  modal2 = false;
  modal3 = false;
  modal4 = false;
  user1 = 'normal';
  numOfProject = 0;
  showDetail = false;
  async addService(): Promise<void> {
    // this.functionPermission = {};
    this.data1 = [];
    this.showDetail = true;
    const res = await UserPermissionSvc({ en_tel: (this.data as any).en_tel, tel: '' });
    if (res && (res as any).data && (res as any).data.user_mid_ary) {
      this.numOfProject = (res as any).data.user_mid_ary.length;
    }
    if (res && (res as any).data && (res as any).data.page_url_obj) {
      this.functionPermission = (res as any).data.page_url_obj;
    }
    const res1 = await getUserByKeySvc({
      en_key: (this.data as any).en_key,
    });
    if (!res1.status && (res1 as any).data && (res1 as any).data.length) {
      this.loading = false;
      (res1 as any).data.forEach((i: any) => {
        this.data1.push({
          id: i.mall_id,
          projectName: i.name,
          province: i.province,
          city: i.city,
          district: i.district,
          _checked:
            (res as any)
            && (res as any).data.user_mid_ary
            && !!(res as any).data.user_mid_ary.find((j: any) => j === i.mall_id),
        });
      });
    }
    const res2 = await getAllEnterpriseSvc({});
    if (!res2.status) {
      this.server = res2.data;
    }
  }
  returnHome(): void {
    this.modal1 = true;
  }
  ok(): void {
    this.$emit('returnHomePage', false, this.currentPage);
  }
  returnHomePage(a: boolean, b: number): void {
    this.$emit('returnHomePage', a, b);
  }
  cancel(): void {
    this.modal1 = false;
  }
  async mounted(): Promise<void> {
    this.data1 = [];
    this.functionPermission = {};
    const res = await UserListEditSvc({ en_tel: (this.data as any).en_tel });
    this.serviceName = (this.data as any).service_name;
    if ((res as any) && (res.data as any)) {
      this.userName = res.data.name;
      this.phone = res.data.tel;
      this.en_tel = res.data.en_tel;
      this.message = res.data.co_name;
      this.value = res.data.name;
    }
    this.value = (this.data as any).user;
    let role: any;
    if ((this.data as any).privilege === 3) {
      role = 'normal';
    } else if ((this.data as any).privilege === 2) {
      role = 'boss';
    } else {
      role = 'systemBoss';
    }
    this.user1 = role;
  }
  async tableDataChange(val: string): Promise<void> {
    this.data1 = [];
    this.loading = true;
    const res = await UserPermissionSvc({ en_tel: (this.data as any).en_tel, tel: '' });
    if (res && (res as any).data && (res as any).data.user_mid_ary) {
      this.numOfProject = (res as any).data.user_mid_ary.length;
    }
    if (res && (res as any).data && (res as any).data.page_url_obj) {
      this.functionPermission = (res as any).data.page_url_obj;
    }
    const res1 = await getUserByKeySvc({
      en_key: val,
    });
    if (!res1.status && (res1 as any).data) {
      this.loading = false;
      if ((res1 as any).data.length) {
        (res1 as any).data.forEach((i: any) => {
          this.loading = false;
          this.data1.push({
            id: i.mall_id,
            projectName: i.name,
            province: i.province,
            city: i.city,
            district: i.district,
            _checked:
            (res as any)
            && (res as any).data.user_mid_ary
            && !!(res as any).data.user_mid_ary.find((j: any) => j === i.mall_id),
          });
        });
      }
    }
  }
}

















































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import userDetail from '@/components/UserDetail/index.vue';
import {
  appendNewPhoneNumberSvc,
  UserPermissionSvc,
  getAllEnterpriseSvc,
  getUserByKeySvc,
} from '@/services/systemApi';
/* eslint-disable @typescript-eslint/naming-convention */

@Component({
  components: {
    userDetail,
  },
})

// TODO  Agent 区域数据需处理，类型需合适定义;
export default class AppendUser extends Vue {
  @Prop({
    type: Number,
    required: false,
    default: 1,
  })
  currentPage!: 0;
  $dayjs: any;
  userManage = true;
  username = '';
  type = 1;
  server: any = [];
  loading = true;
  functionPermission: any = {};
  companyInformation = '';
  tips = '';
  value = '';
  user = 'normal';
  data1: any = [];
  value1 = '';
  showDetail = false;
  modal1 = false;
  modal7 = false;
  modal8 = false;
  companyInfo = false;
  numOfProject = 0;
  @Watch('value')
  valueChange(): void {
    if (this.showDetail === true) {
      this.companyInfo = false;
      this.showDetail = false;
    }
  }
  returnHome(): void {
    this.modal1 = true;
  }
  ok(): void {
    this.$emit('returnHomePage', false, this.currentPage);
  }
  returnHomePage(a: boolean, b: number): void {
    this.$emit('returnHomePage', a, b);
  }
  async searchUser(): Promise<void> {
    this.tips = '';
    this.companyInfo = false;
    const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    if (!this.value) {
      this.modal8 = true;
    } else {
      if (reg.test(this.value)) {
        const res = await appendNewPhoneNumberSvc({
          tel: this.value,
        });
        if (!res.status) {
          this.companyInfo = true;
          this.username = res.data.name;
          this.companyInformation = res.data.co_name;
        } else {
          this.modal7 = true;
          this.tips = res.info;
        }
      } else {
        this.modal7 = true;
      }
    }
  }
  async addServiceInfo(): Promise<void> {
    this.showDetail = true;
    const res = await UserPermissionSvc({ tel: this.value, en_tel: '' });
    if (res && (res as any).data && (res as any).data.page_url_obj) {
      this.functionPermission = (res as any).data.page_url_obj;
    }
    const res1 = await getUserByKeySvc({
      user_key: '6M3BZ-CSP3U-ORPVM-2XMEW-RA5CQ-SQFW4',
    });
    if (!res1.status && (res1 as any).data && (res1 as any).data.length) {
      this.loading = false;
      (res1 as any).data.forEach((i: any) => {
        this.data1.push({
          id: i.mall_id,
          projectName: i.name,
          province: i.province,
          city: i.city,
          district: i.district,
          _checked:
            (res as any)
            && (res as any).data.user_mid_ary
            && !!(res as any).data.user_mid_ary.find((j: any) => j === i.mall_id),
        });
      });
    }
    const res2 = await getAllEnterpriseSvc({});
    if (!res2.status) {
      this.server = res2.data;
    }
  }
  modalShow(): void {
    this.modal7 = false;
    this.modal8 = false;
  }
  async tableDataChange(val: string): Promise<void> {
    this.data1 = [];
    this.loading = true;
    const res1 = await getUserByKeySvc({
      en_key: val,
    });
    if (!res1.status && (res1 as any).data && (res1 as any).data.length) {
      (res1 as any).data.forEach((i: any) => {
        this.data1.push({
          id: i.mall_id,
          projectName: i.name,
          province: i.province,
          city: i.city,
          district: i.district,
          _checked: false,
        });
      });
    }
    this.loading = false;
  }
}

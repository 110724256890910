



































































































import { Vue, Component } from 'vue-property-decorator';
import operateUser from './operateUser/index.vue';
import appendUser from './appendUser/index.vue';
import { getUserInfoSvc, changeUserStatusSvc } from '@/services/systemApi';
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

@Component({
  components: {
    operateUser,
    appendUser,
  },
})

// TODO  Agent 区域数据需处理，类型需合适定义;
export default class UserManage extends Vue {
  operateInfo: any = [];
  appendUserStatus = false;
  modal2 = false;
  modal3 = false;
  total = 0;
  status = 0;
  tel = '';
  en_tel = '';
  loading = true;
  cityList = [
    {
      label: '用户ID',
      value: '1',
    },
    {
      label: '用户名称',
      value: '2',
    },
    {
      label: '关联企业服务',
      value: '3',
    },
    {
      label: '用户类型',
      value: '4',
    },
    {
      label: '服务状态（已启用|已停用）',
      value: '6',
    },
    {
      label: '用户手机号',
      value: '7',
    },
  ];
  content = '';
  modal = false;
  modal1 = false;
  value = '';
  current = 1;
  model1 = '0';
  showPage = false;
  districtStatus = [
    {
      value: 'all',
      label: '全部',
    },
    {
      value: 'pass',
      label: '已通过',
    },
    {
      value: 'review',
      label: '待审核',
    },
    {
      value: 'fail',
      label: '已驳回',
    },
  ];

  columns1 = [
    {
      title: '用户ID',
      key: 'id',
      align: 'center',
    },
    {
      title: '用户名称',
      key: 'user',
      align: 'center',
    },
    {
      title: '关联企业服务',
      key: 'service_name',
      align: 'center',
      tooltip: true,
    },
    {
      title: '用户手机号',
      key: 'tel',
      align: 'center',
      tooltip: true,
    },
    {
      title: '用户类型',
      key: 'privilege',
      align: 'center',
      slot: 'privilege',
    },
    {
      title: '更新时间',
      key: 'pass_time',
      align: 'center',
      tooltip: true,
    },
    {
      title: '操作',
      key: 'operate',
      slot: 'operate',
      align: 'center',
    },
  ];
  typeTextObj: any = {
    系统管理员: '1',
    企业管理员: '2',
    普通用户: '3',
  };
  typeTextObjStatue: any = {
    已启用: '1',
    已停用: '0',
  };
  data1: any = [];
  mounted(): void {
    this.getUserMes(this.current);
  }
  modalShow(): void {
    this.modal3 = false;
  }
  async getUserMes(page: number): Promise<void> {
    this.value = this.value.trim();
    let { value } = this;
    if (parseInt(this.model1, 10) === 4) {
      value = this.typeTextObj[this.value] || '';
    }
    if (parseInt(this.model1, 10) === 6) {
      value = this.typeTextObjStatue[this.value] || this.value;
    }
    const res = await getUserInfoSvc({
      page,
      rows: 10,
      search_type: this.model1 ? parseInt(this.model1, 10) : 0,
      search_str: value,
    });
    if ((res as any).status === 0 || (res as any).status === 2) {
      this.total = Number((res as any).total);
      this.data1 = (res as any).data;
      this.loading = false;
    }
  }
  returnHomePage(val: boolean, page: number): void {
    this.appendUserStatus = val;
    this.showPage = val;
    this.current = page;
    console.log(this.current);

    this.getUserMes(this.current);
  }
  addUser(): void {
    this.appendUserStatus = true;
  }
  goBack(val: boolean): void {
    this.showPage = val;
  }
  reset(): void {
    this.model1 = '0';
    this.value = '';
    this.current = 1;
    this.getUserMes(this.current);
  }
  searchContent(): void {
    if (!this.model1) {
      this.modal = true;
    } else if (!this.value) {
      this.modal1 = true;
    } else if (this.value && this.model1) {
      this.current = 1;
      this.getUserMes(this.current);
    }
  }
  pageNumChange(val: number): void {
    this.current = val;
    this.getUserMes(this.current);
  }
  showContent(val: any): void {
    this.showPage = true;
    this.operateInfo = val;
    this.content = val;
  }
  async userStatus(val: any): Promise<void> {
    this.modal2 = true;
    this.status = val.status;
    this.tel = val.tel;
    this.en_tel = val.en_tel;
  }
  async ok(): Promise<void> {
    const res = await changeUserStatusSvc({
      en_tel: this.en_tel,
      status: this.status === 1 ? 0 : 1,
    });
    this.getUserMes(this.current);
    if (!res.status) {
      this.modal3 = true;
    }
  }
}
